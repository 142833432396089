import styled from '@mui/material/styles/styled'
import { StyledLinkAsButton } from '@components/UI/Button/LinkAsButton'
import { StyledButton } from '@components/UI/Button/Button'

export const WrapperPromotionBar = styled('div', {
  name: 'PromotionBar',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'mobileMinHeight',
})<{ mobileMinHeight: number }>(({ mobileMinHeight, theme }) => ({
  height: mobileMinHeight,
  width: '100%',
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 'normal',
  display: 'flex',
  backgroundColor: theme.palette.background.dark.primary,
  color: theme.palette.text.light.primary,
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    height: 40,
  },
}))

export const ContentPromotionBar = styled('div', {
  name: 'PromotionBar',
  slot: 'Content',
})(({ theme }) => ({
  height: 'inherit',
  width: 'inherit',
  display: 'flex',
  padding: `0 ${theme.spacing(4)}`,
}))

export const ContentSlide = styled('div', {
  name: 'PromotionBar',
  slot: 'ContentSlide',
})(({ theme }) => ({
  width: '100vw',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
  height: 'inherit',
  alignItems: 'center',
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 600,
  lineHeight: 1.33,
  justifyContent: 'center',
  gap: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
    width: 'auto',
  },
}))

export const TextPromotionBar = styled('div', {
  name: 'PromotionBar',
  slot: 'Text',
})(() => ({
  textAlign: 'center'
}))

export const LinkPromotionBar = styled(StyledLinkAsButton, {
  name: 'PromotionBar',
  slot: 'Link',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  borderColor: theme.palette.text.light.primary,
  padding: '2px 5px 2px',
  height: '15px',
  fontWeight: theme.typography.fontWeightBold,
  '&:hover': {
    backgroundColor: theme.palette.custom.grey,
    borderColor: theme.palette.text.light.primary,
  }
}))

export const ButtonPromotionBar = styled(StyledButton, {
  name: 'PromotionBar',
  slot: 'Button',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  borderColor: theme.palette.text.light.primary,
  padding: '10px 5px',
  height: '15px',
  minWidth: '120px',
  fontWeight: theme.typography.fontWeightBold,
  '&:hover': {
    backgroundColor: theme.palette.custom.grey,
    borderColor: theme.palette.text.light.primary,
  }
}))
